import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/ci-meta.png'
import EngineerIcon from '../../assets/images/home/icons/chart.svg'
import PlatformIcon from '../../assets/images/home/icons/compass.svg'
import LeaderIcon from '../../assets/images/home/icons/diamond-hand.svg'
import tag from '../../assets/images/tags/ci-visibility.svg'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import Br from '../../components/system/Br'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/git-analytics.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/ci-lead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/ci-insights-organization-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/ci-insights-workflow-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/github-slack/ci-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
  }
`

const CiInsightsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="CI visibility"
      variant="dark"
      isNew
      description="Get full visibility into your CI/CD pipelines and find ways to speed them up by drilling down into individual repositories and workflows."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Speed up your CI/CD pipelines</>}
        content="Get full visibility into your CI/CD pipelines and find ways to speed them up by drilling down into individual repositories and workflows."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <LeadBlock
        heading="Improve the health and performance of your CI environment"
        content="Whether you lead the engineering organization, work as an individual contributor, or anything in between, Swarmia’s CI insights help you troubleshoot pipeline issues to reduce waiting times and costs."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <ColumnsBlock
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Platform teams"
            text="Measure and improve CI performance at the org-level."
            icon={PlatformIcon}
          />,
          <Blurb
            key="2"
            title="Engineering leaders"
            text="Reduce CI costs by resolving bottlenecks in your pipelines."
            icon={LeaderIcon}
          />,
          <Blurb
            key="3"
            title="Software engineers"
            text="Dive into individual workflows to troubleshoot and fix slowness."
            icon={EngineerIcon}
          />,
        ]}
      />
      <QuoteBlock person="pau" />
      <LeadBlock
        heading="Drill down to find the bottlenecks in your CI pipelines"
        content="Start from the organization view to evaluate the overall performance of your CI environment. Drill down to troubleshoot issues across individual repositories and workflows."
      />
      <MosaicBlock
        title={<>Get a bird’s eye view of&nbsp;your&nbsp;pipelines</>}
        content="Evaluate the overall health and performance of your CI environment with the organization-level insights."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Drill down to take action"
        content="Dive deeper into individual repositories to find the workflows with the most room for improvement. Then, analyze which parts of your workflows fail frequently or take the longest to run to identify the root causes for slowness or flakiness."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={
          <>
            Quickly catch failed
            <Br />
            CI checks
          </>
        }
        content="Get automatic Slack alerts about failed CI checks in your PRs. To eliminate noisy pings, you’ll only get CI failure notifications after you’ve requested a review."
        image={getImage(data.mosaicLeft2)!}
      />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title="Start speeding up your CI/CD pipelines today. Available on the Lite plan." />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/continuous-integration/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default CiInsightsPage
